import React from "react"
import { navigate } from "@reach/router"
import Form from "../components/form"
import Layout from "../components/layout"
import { handleFormPost } from "../utils/send-form"

const DEFAULT_BUTTON_TEXT = `Hmmm.. not ready yet`;
const POSITIVE_BUTTON_TEXT = `Looks good! Let's send it!`;
const WRONG_EMAIL_BUTTON_TEXT = `Not yet.. Check your email`;

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      name: ``,
      email: ``,
      message: ``,
      buttonText: DEFAULT_BUTTON_TEXT,
      formReady: false
    }
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUpdate(event) {
    console.log('changed', event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    }, () => {

      if(this.state.name !== '' && this.state.email !== '' && this.state.message !== ''){
        const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailOk = regexEmail.test(this.state.email);
        console.log('email test', isEmailOk);
        if(isEmailOk) {
          this.setState(
            {
              buttonText: POSITIVE_BUTTON_TEXT,
              formReady: true
            }
          );
        } else {
          this.setState(
            {
              buttonText: WRONG_EMAIL_BUTTON_TEXT,
              formReady: false
            }
          ); 
        }
      } else {
        this.setState(
          { 
            buttonText: DEFAULT_BUTTON_TEXT,
            formReady: false
          }
        );
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    handleFormPost(this.state)
    .then(res => {
      navigate(`/success`);
    })
    .catch (err => {
      // window.alert('Something went wrong');
      // navigate(`/success`);
    })
  }

  render() {
    return (
      <Layout>
      <h2>Contact</h2>
      <Form
      handleUpdate={e => this.handleUpdate(e)}
      handleSubmit={e => this.handleSubmit(e)}
      state= {this.state}
      />
      <p> You can also find me on social networks: </p>
      <p>
        <a target="_blank" href="https://twitter.com/claudiotx7"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_Twitter.png" alt="Made in Portugal" /></a>
        <a target="_blank" href="https://www.linkedin.com/in/cl%C3%A1udio-teixeira-138a9a3a/"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_LinkedIn.png" alt="Made in Portugal" /></a>
        <a target="_blank" href="https://www.facebook.com/claudiotx?ref=bookmarks"> <img className="social" src="https://cdn-salecycle.s3.amazonaws.com/dev/SocialMedia/Black_Circle_Facebook.png" alt="Facebook"></img> </a>
        <a target="_blank" href="https://github.com/claudiotx"> <img className="social" src="../GitHub-Mark-64px.png" alt="Github"></img> </a>
      </p>
      {/* <a href="https://www.linkedin.com/claudiotx"> <img src="http://i.imgur.com/wWzX9uB.png" alt="Made in Portugal" /></a> */}
      </Layout>
      )
    }
  }

  export default Contact