import React from "react"
import styles from "./form.module.css"

export default ({ handleSubmit, handleUpdate, state }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
    <p className={styles[`form__instructions`]}>
      Let's get in touch!
    </p>

    <label className={styles[`form__label`]}>
      Your pretty name
      <input
        className={styles[`form__input`]}
        type="text"
        placeholder="John"
        validators={["required", "isEmail"]}
        name="name"
        onChange={handleUpdate}
      />
    </label>

    <label className={styles[`form__label`]}>
      Your fancy email (or phone)
      <input
        className={styles[`form__input`]}
        type="text"
        placeholder="getintouch@me.com"
        validators={["required", "isEmail"]}
        name="email"
        onChange={handleUpdate}
      />
    </label>

    <label className={styles[`form__label`]}>
      Your interesting message
      <textarea
        className={styles[`form__input`]}
        rows="6"
        validators={["required", "isEmail"]}
        placeholder="Hello Claudio..."
        name="message"
        onChange={handleUpdate}
      />
    </label>

    <input className={styles[`form__button`]} type="submit" value={state.buttonText} disabled={!state.formReady}/>
  </form>
)
