const isBrowser = typeof window !== `undefined`

const sendForm = (data) => {
  const parsedData = JSON.stringify(data);
  return fetch("https://www.coderecipes.org/api/contact/", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: parsedData
  })
}

export const handleFormPost = ({ name, email, message }) => {
  if (!isBrowser || !name || !email || !message) return new Promise((resolve,reject) => setTimeout(reject('Missing fields'),0));
  const formBody = { name, email, message };
  return sendForm(formBody);
}